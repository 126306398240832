import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

const Stage = () => {
  const data = useStaticQuery(graphql`
        {
            imageSharp(fluid: {originalName: {eq: "scene_bg_lg.jpg"}}) {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    `);

  const BGImage = data.imageSharp.fluid;

  const ctaStyle = {
    maxWidth: '400px',
  };

  return (

    <div className="stage">
      <BackgroundImage
        Tag="div"
        className="stage-bg"
        fluid={BGImage}
      >

        <section className="inner-stage">
          <div className="fixed-width-md">
            <h1>Wilkommen auf dem Rundholzportal</h1>
            <p>Die Kommunikationsplattform für Waldbesitzer, Spediteure und Holzverarbeiter.</p>
            <a style={ctaStyle} className="button primary gap-top-lg" href="https://www.rundholzportal.de/Account/Registrieren">Registrieren</a>
          </div>
        </section>
      </BackgroundImage>
    </div>
  );
};
export default Stage;
